* {
  font-family: "Montserrat", sans-serif;
}

h1 {
  margin-top: 40px;
  color: #989898;
  margin-bottom: 4%;
  text-align: center;
  font-size: calc(50px + 2vmin);
}

h3 {
  color: #000000;
  margin-bottom: 0%;
  text-align: center;
  font-size: calc(20px + 2vmin);
}

h4 {
  color: #626262;
  text-align: center;
  margin-bottom: 0%;
  font-size: calc(8px + 2vmin);
}

p {
  color: #626262;
  text-align: justify;
  font-size: 20px;
}

#textoNormas {
  color: #626262;
  text-align: justify;
  font-size: 20px;
}

button {
  background-color: #0084fb;
  border-color: #0084fb;
  color: #ffffff;
  border-radius: 0;
  font-size: 20px;
}

button:hover,
button:focus,
button:active,
.open .dropdown-toggle.button,
button:active:focus,
button:active:hover,
button.active:hover,
button.active:focus {
  background-color: #ff5d00;
  border-color: #ff5d00;
  color: #ffffff;
}

button:active,
button.active,
.open .dropdown-toggle.button {
  background-image: none;
}

button.disabled,
button.disabled:hover,
button.disabled:focus,
button.disabled:active,
button.disabled.active,
button[disabled],
button[disabled]:hover,
button[disabled]:focus,
button[disabled]:active,
button.active[disabled],
fieldset[disabled] .button,
fieldset[disabled] .button:hover,
fieldset[disabled] .button:focus,
fieldset[disabled] .button:active,
fieldset[disabled] .button.active {
  background-color: #989898;
  border-color: #989898;
  /*background-color: #1dc5a3;
    border-color: #1dc5a3;*/
}

/* .botonDesactivado {
  background-color: #989898;
  border-color: #989898;
} */

#tablaMui {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#textoInfo {
  padding-left: 18%;
  padding-right: 18%;
  padding-top: 30px;
}

#camposFormDenuncia {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
}

#contStreet {
  width: 100%;
}

#streetInput {
  width: 52%;
  margin-bottom: 10px;
  /* margin-left: -1.1rem; */
}

#numberStreetInput {
  width: 47%;
  margin-left: 0.4rem;
}

#cityInput {
  margin-right: 0.5rem;
}

#provinceInput {
  margin-right: 0.5rem;
}

#pcInput {
  margin-right: 0.5rem;
}

#tablaMui {
  box-shadow: none;
}

#casillasTabla {
  font-family: "Montserrat", sans-serif;
}

#checkbox {
  text-align: center;
  font-size: 14px;
}

#radiob {
  text-align: center;
  font-size: 20px;
}

#camposInput {
  text-align: justify;
  font-size: 20px;
}

#selectorBar {
  font-size: 20px;
  color: #626262;
}

#descargarResolucion {
  display: inline-block;
  vertical-align: middle;
}

.Codigo {
  color: #000000;
  font-size: 44px;
  font-weight: bolder;
  text-align: center;
  margin-top: 30px;
}

.myCAPTCHA {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

@media (max-width: 685px) {
  #textoInfo {
    padding-left: 2rem;
    padding-right: 2rem;
    word-spacing: 3px;
  }

  #camposFormDenuncia {
    width: 90%;
  }

  #inputFormDenuncia {
    margin-bottom: 0;
  }

  #contStreetInput {
    width: 100%;
  }

  #streetInput {
    margin-right: 0.1rem;
  }

  #numberStreetInput {
    margin-left: 0;
  }

  #cityInput {
    width: 90%;
    margin-right: 0.7rem;
  }

  #provinceInput {
    margin-right: 0.7rem;
  }
}

/* @media (min-width: 320px) {
  #contBotonesCodigo {
    flex-direction: row;
  }
} */

.selectError > div {
  border-color: red;
}
.selectError #react-select-5-placeholder {
  color: red;
}
