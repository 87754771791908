
#textoNormas{
    padding : 150px;
    margin-top: -100px;
}

.numerated-list {
    counter-reset: item;
    list-style: none;
    
  }
  
.numerated-list dt:before {
    counter-increment: item;
    content: counter(item) ". ";
    font-weight: bold;
}

li{
    list-style-type: circle;
    font-size: 18px;
}

dt{
    margin-top:20px;
    font-size: 22px;
}

